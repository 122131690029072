import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './css/reset.css';
import './css/style.css';

const IndexComponent = () => {
  useEffect(() => {
    // Typewriter effect
    const TxtType = function(el, toRotate, period) {
      this.toRotate = toRotate;
      this.el = el;
      this.loopNum = 0;
      this.period = parseInt(period, 10) || 2000;
      this.txt = '';
      this.tick();
      this.isDeleting = false;
    };

    TxtType.prototype.tick = function() {
      const i = this.loopNum % this.toRotate.length;
      const fullTxt = this.toRotate[i];
      this.txt = this.isDeleting ? fullTxt.substring(0, this.txt.length - 1) : fullTxt.substring(0, this.txt.length + 1);
      this.el.innerHTML = `<span className="wrap">${this.txt}</span>`;
      let delta = 200 - Math.random() * 100;
      if (this.isDeleting) { delta /= 2; }
      if (!this.isDeleting && this.txt === fullTxt) {
        delta = this.period;
        this.isDeleting = true;
      } else if (this.isDeleting && this.txt === '') {
        this.isDeleting = false;
        this.loopNum++;
        delta = 500;
      }
      setTimeout(() => { this.tick(); }, delta);
    };

    const elements = document.getElementsByClassName('typewrite');
    for (let i = 0; i < elements.length; i++) {
      const toRotate = elements[i].getAttribute('data-type');
      const period = elements[i].getAttribute('data-period');
      if (toRotate) {
        new TxtType(elements[i], JSON.parse(toRotate), period);
      }
    }
  }, []);

  return (
    <div>
      {/* Hero Section */}
      <section className="hero-section">
        <div className="hero-content">
            <div className="hero-wrapper">
            <header className="header">
                <div className="logo-wrapper">
                  <a href="/demo"><img src="sg-type.png" alt="Standard Giving Logo" className="logo" /></a>
                </div>
                <nav className="nav-wrapper">
                  <div className="nav-links">
                    <a href="mailto:partners@standardgiving.com" className="nav-item">Contact Us</a>
                  </div>
                </nav>
              </header>
                <h1 className="hero-title"><span>Helping People Can Be Hard.</span><br />Standard Giving Co. Makes It Easy.</h1>
                <div className="hero-subtitle-wrapper">
                    <p className="hero-subtitle">Standard Giving Co. introduces an entirely new model to leverage your influence to change thousands of <span className="typewrite" data-period="2000" data-type='[ "people&#39;s", "students&#39;", "patients&#39;", "athletes&#39;", "families&#39;", "alumni&#39;s", "customers&#39;", "fans&#39;", "strangers&#39;" ]'></span> lives.</p>
                    <button className="cta-button"><a href="mailto:partners@standardgiving.com">Start Now</a></button>
                </div>
            </div>
        </div>
      </section>

      {/* Mission Section */}
      {/* <section className="mission-section">
        <div className="mission-wrapper">
          <h2 className="mission-title">Our Goal is Simple:<br /> Make Generosity a Standard.</h2>
          <p className="mission-description">Whether you're looking to contribute or need support yourself, we're here to make it easy, transparent, and human.</p>
          <p className="mission-paragraph">We partner with businesses, creators, and everyday people to build a collective <strong>pool of support</strong>, with contributions flowing <u>directly</u> to those who need it most.</p>
          <p className="mission-explanation">Think of it as "<strong>subscribing to kindness</strong>"—monthly contributions that fuel meaningful impact and help others take a step forward. And with real-time impact reports, you'll see exactly how your support is making a difference.</p>
          <p className="support-paragraph">For those <strong>seeking financial support,</strong> we've created a straightforward process to ensure that real people get real help.</p>
          <p className="support-explanation">Once verified, the funds are sent directly to cover the selected beneficiary's outstanding financial obligations, so every contribution brings meaningful relief.</p>
          <div className="mission-cta">Join us in creating a world where giving is the standard and everyone has a shot at a better future.</div>
        </div>
      </section> */}

      {/* Benefits Section */}
      <section className="benefits-section">
        <div className="benefits-wrapper">
          <div className="benefits-content">
            <div className="benefit-column">
              <div className="benefit-text">
                <h3 className="benefit-title">Impact That Builds Loyalty</h3>
                <p className="benefit-description">Helping communities thrive strengthens more than goodwill—it strengthens loyalty. With Standard Giving Co., Partners can see their support in action, building meaningful relationships and deeper brand connections.</p>
              </div>
            </div>
            <div className="benefit-image-column">
              <img src="impact-img.png" alt="Impact visualization" className="benefit-image" />
            </div>
            <div className="engagement-column">
              <div className="engagement-text">
                <h3 className="engagement-title">Purpose-Driven Engagement</h3>
                <p className="engagement-description">Transparency and real-time reporting foster trust, while engaging content keeps people involved. Partners benefit from meaningful engagement that extends beyond transactions and strengthens community bonds.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* How It Works Section */}
      <section className="how-it-works-section">
        <h2 className="how-it-works-title">How it Works</h2>
        <div className="how-it-works-content">
          <div className="step-wrapper">
            <div className="step-content">
              <div className="step-header">
                <p className="step-number">Step 1</p>
                <h3 className="step-title">Create a Kindness Campaign</h3>
              </div>
              <p className="step-description">Partners launch customized kindness campaigns and share them with their communities.</p>
            </div>
            <img src="how-img-1.png" alt="Campaign Creator" className="step-1-image" />
          </div>

          <div className="step-2-wrapper">
            <div className="step-2-content">
              <div className="step-2-header">
                <p className="step-2-number">Step 2</p>
                <h3 className="step-2-title">Kindness Activated</h3>
              </div>
              <p className="step-2-description">Members choose to "Subscribe to Kindness," either monthly or annually, driving lasting impact and getting access to exclusive content and monthly impact reports.</p>
            </div>
            <img src="how-img-2.png" alt="Kindness Activation" className="step-2-image" />
          </div>

          <div className="step-3-wrapper">
            <div className="step-3-content">
              <div className="step-3-header">
                <p className="step-3-number">Step 3</p>
                <h3 className="step-3-title">Application & Verification</h3>
              </div>
              <p className="step-3-description">Potential beneficiaries apply, and Standard Giving Co. verifies eligibility based on the specific campaign criteria set by Partners. This ensures funds go where they're needed most.</p>
            </div>
            <img src="how-img-3.png" alt="Application and Verification" className="step-3-image" />
          </div>

          <div className="step-4-wrapper">
            <div className="step-4-content">
              <div className="step-4-header">
                <p className="step-4-number">Step 4</p>
                <h3 className="step-4-title">Impact Made</h3>
              </div>
              <p className="step-4-description">Qualified beneficiaries receive direct financial support, with every contribution making an immediate impact. Subscribers and Partners enjoy real-time impact reports that reveal exactly how their contributions are making a difference.</p>
            </div>
            <img src="how-img-4.png" alt="Impact Made" className="step-4-image" />
          </div>

          <button className="partner-cta"><a href="mailto:partners@standardgiving.com">Become a Partner</a></button>
        </div>
      </section>

      {/* Trust Section */}
      <section className="trust-section">
        <h2 className="trust-title">Trust. Security. Lasting Impact.</h2>
        <div className="trust-content">
          <div className="trust-item">
            <h3 className="trust-item-title"><img src="heart.svg" alt="heart icon" className="trust-icon" /> Driven by Purpose</h3>
            <p className="trust-item-description">As a Public Benefit Corporation (PBC), we’re more than a platform—we’re a mission-driven community dedicated to meaningful impact. Our PBC status holds us accountable to this mission, ensuring every action and contribution aligns with our purpose: helping people in need and making a positive difference.</p>
          </div>
          <div className="trust-item">
            <h3 className="trust-item-title"><img src="locked-2.svg" alt="lock icon" className="trust-icon" /> Trusted and Secure</h3>
            <p className="trust-item-description">We know your privacy is important. That’s why we use advanced encryption and secure data storage to protect any personal information you provide. From your account details to your payment info, we keep everything safeguarded, so you can give with confidence and peace of mind.</p>
          </div>
          <div className="trust-item">
            <h3 className="trust-item-title"><img src="check-circle-1.svg" alt="check icon" className="trust-icon" /> Compliance You Can Count On</h3>
            <p className="trust-item-description">We adhere to rigorous standards, ensuring our platform remains safe, fair, and effective. We take every measure to protect and empower our community, building trust every step of the way.</p>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="footer">
        <div className="footer-content">
          <div className="footer-logo-section">
            <img src="sg-type-inv.png" alt="Standard Giving Logo" className="footer-logo" />
            <p className="footer-tagline">Helping people can be hard.<br />Standard Giving Co. makes it easy.</p>
          </div>
          <div className="footer-links">
            <div className="footer-link-column">
              <a href="mailto:partners@standardgiving.com" className="footer-link">Become a Partner</a>
              {/* <a href="#" className="footer-link">Subscriptions</a>
              <a href="#faq" className="footer-link">FAQ</a> */}
              <a href="mailto:partners@standardgiving.com" className="footer-link">Contact Us</a>
            </div>
            <div className="footer-link-column">
              <Link to="/terms" className="footer-link">Terms of Use</Link>
              <Link to="/privacy-policy" className="footer-link">Privacy Policy</Link>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="footer-wrapper">
            <p className="footer-copyright">© 2024 Standard Giving Co. All Rights Reserved</p>
            <div className="footer-social">
                {/* <a href="#" aria-label="Instagram"><img src="icn-instagram.png" alt="Instagram" className="social-icon-footer" /></a>
                <a href="#" aria-label="youtube"><img src="icn-YouTube.png" alt="youtube" className="social-icon-footer" /></a>
                <a href="#" aria-label="Twitter"><img src="icn-x.png" alt="Twitter" className="social-icon-footer" /></a> */}
              </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default IndexComponent;
