import React from 'react';
import './css/reset.css';
import './css/style.css';

const ComingSoonComponent = () => {
  return (
    <div className="coming-soon-page">
        <div className="coming-soon-wrapper">
            <img src="sg-type.png" alt="Standard Giving Logo" className="coming-soon-logo"></img>
            <h1>COMING SOON</h1>
      </div>
    </div>
  );
};

export default ComingSoonComponent;
